import axios from 'axios';

export default {
    async insertNew(token, params) {
        return await axios.post(process.env.VUE_APP_ENDPOINT_API + "/api/schede/insert_new?token="+token, params).then(response => {
            return response.data;
        });
    },
    async getAll(token, id) {
        return await axios.get(process.env.VUE_APP_ENDPOINT_API + "/api/schede/get_all?token="+token+"&id_stabilimento="+id).then(response => {
            return response.data;
        });
    },
    async getAllNames(token, id) {
        return await axios.get(process.env.VUE_APP_ENDPOINT_API + "/api/schede/get_all_names?token="+token+"&id_stabilimento="+id).then(response => {
            return response.data;
        });
    },
    async getOne(_id) {
        return await axios.get(process.env.VUE_APP_ENDPOINT_API + "/api/schede/get_one?_id="+_id).then(response => {
            return response.data;
        });
    },
    async updateOne(token, params) {
        return await axios.post(process.env.VUE_APP_ENDPOINT_API + "/api/schede/update_one?token="+token, params).then(response => {
            return response.data;
        });
    },
    async deleteOne(token, params) {
        return await axios.post(process.env.VUE_APP_ENDPOINT_API + "/api/schede/delete_one?token="+token, params).then(response => {
            return response.data;
        });
    },
}