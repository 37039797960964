<template>
    <div class="schede">
        <v-tabs v-model="tab" v-if="(schede && schede.length) || noDatas" :height="disabled ? '0' : ''" :class="disabled ? 'removeArrow' : ''" :show-arrows="false">
            <v-tab
                v-for="scheda in schede"
                :key="scheda._id"
                :disabled="disabled"
            >
                <v-chip
                    class="px-5 white--text"
                    :color="scheda.colore"
                >
                    {{ scheda.lettera }}
                </v-chip>
            </v-tab>
            <v-tab v-if="hasPermission">
                <v-chip
                    class="px-2"
                    color="greyColor"
                    outlined
                >
                    <v-icon small>fas fa-plus</v-icon>
                </v-chip>
            </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab" v-if="(schede && schede.length) || noDatas" :touchless="disabled">
            <v-tab-item
                v-for="scheda in schede"
                :key="scheda._id"
                class="border"
            >
                <v-card>
                    <v-card-title class="subheading font-weight-bold white--text" :style="'background-color:'+scheda.colore">
                        <span>{{ scheda.lettera }}</span>
                        <v-spacer></v-spacer>
                        <v-btn
                            v-if="hasPermission && actionScheda"
                            icon
                            :color="!(scheda.evacuatoriLength == 0 && !loading) ? 'darkGrey' : 'white'"
                            @click="deleteScheda(scheda._id, scheda.evacuatoriLength)"
                            class="mt-1"
                        >
                            <v-icon small>fas fa-trash</v-icon>
                        </v-btn>
                    </v-card-title>

                    <v-divider></v-divider>

                    <v-list dense>
                        <v-list-item>
                            <v-list-item-content class="bold">
                                <small>Dimensioni</small>
                            </v-list-item-content>
                            <v-list-item-content class="align-end">
                                {{ scheda.dimensioni }}
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-content class="bold">
                                <small>Produttore</small>
                            </v-list-item-content>
                            <v-list-item-content class="align-end">
                                {{ scheda.produttore }}
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-content class="bold">
                                <small>Bombola</small>
                            </v-list-item-content>
                            <v-list-item-content class="align-end inline">
                                <div class="inline">
                                    <v-chip
                                        class="px-2"
                                        max-width="20px"
                                        outlined
                                        color="greyColor"
                                        small
                                        v-if="scheda.marca_bombola"
                                    >
                                        <b>{{ scheda.marca_bombola }}</b>
                                    </v-chip>
                                </div>
                                <span class="mt-4 ml-2">{{ scheda.bombola }}</span>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-content class="bold">
                                <small>Ampolla</small>
                            </v-list-item-content>
                            <v-list-item-content class="align-end inline">
                                <div class="inline">
                                    <v-chip
                                        class="px-2"
                                        max-width="20px"
                                        outlined
                                        color="greyColor"
                                        small
                                        v-if="scheda.marca_ampolla"
                                    >
                                        <b>{{ scheda.marca_ampolla }}</b>
                                    </v-chip>
                                </div>
                                <span class="mt-4 ml-2">{{ scheda.ampolla }}</span>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="!scheda.nuovo">
                            <v-list-item-content class="bold">
                                <small>Pirotecnico</small>
                            </v-list-item-content>
                            <v-list-item-content class="align-end inline">
                                <div class="inline">
                                    <v-chip
                                        class="px-2"
                                        max-width="20px"
                                        outlined
                                        color="greyColor"
                                        small
                                        v-if="scheda.marca_pirotecnico"
                                    >
                                        <b>{{ scheda.marca_pirotecnico }}</b>
                                    </v-chip>
                                </div>
                                <span class="mt-4 ml-2">{{ scheda.pirotecnico }}</span>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="!scheda.nuovo">
                            <v-list-item-content class="bold">
                                <small>Elettromagnete</small>
                            </v-list-item-content>
                            <v-list-item-content class="">
                                {{ scheda.elettromagnete }}
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="scheda.nuovo">
                            <v-list-item-content class="bold">
                                <small>Attuatore</small>
                            </v-list-item-content>
                            <v-list-item-content class="align-end inline">
                                <div class="inline">
                                    <v-chip
                                        class="px-2"
                                        max-width="20px"
                                        outlined
                                        color="greyColor"
                                        small
                                        v-if="scheda.attuatore"
                                    >
                                        <b>{{ scheda.attuatore }}</b>
                                    </v-chip>
                                </div>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-content class="bold">
                                <small>Motore</small>
                            </v-list-item-content>
                            <v-list-item-content class="align-end">
                                {{ scheda.motore }}
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-content class="bold">
                                <small>Note</small>
                            </v-list-item-content>
                            <v-list-item-content class="align-end">
                                {{ truncateText(scheda.note, 40) }}
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-card>
            </v-tab-item>
            <v-tab-item
                class="border"
                v-if="hasPermission"
            >
                <v-card>
                    <v-card-title class="subheading font-weight-bold white--text primary">
                        Nuova Scheda
                    </v-card-title>

                    <v-divider></v-divider>

                    <v-form v-model="formValid" ref="form_data" lazy-formValidation>
                        <v-list dense>
                            <v-list-item>
                                <v-list-item-content>
                                    Titolo
                                </v-list-item-content>
                                <v-list-item-content class="align-end">
                                    <v-text-field
                                        autocomplete="off"
                                        v-model="newScheda_titolo"
                                        hide-details="auto"
                                        required
                                        :rules="titoloRules"
                                        label="Titolo"
                                        class="mt-0 pt-1"
                                    ></v-text-field>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-content>
                                    Colore
                                </v-list-item-content>
                                <v-list-item-content class="align-end">
                                    <v-menu offset-y min-width="auto" :close-on-content-click="false">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                autocomplete="off"
                                                label="Colore"
                                                readonly
                                                v-model="newScheda_colore"
                                                v-bind="attrs"
                                                hide-details
                                                class="mt-0 pt-1"
                                                v-on="on"
                                            ></v-text-field>
                                        </template>
                                        <v-color-picker
                                            dot-size="30"
                                            hide-inputs
                                            class="no-alpha"
                                            hide-mode-switch
                                            canvas-height="100"
                                            v-model="newScheda_colore"
                                            swatches-max-height="150"
                                        ></v-color-picker>
                                    </v-menu>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-content>
                                    Dimensioni
                                </v-list-item-content>
                                <v-list-item-content class="align-end">
                                    <v-text-field
                                        autocomplete="off"
                                        v-model="newScheda_dimensioni"
                                        hide-details="auto"
                                        :rules="requiredRules"
                                        label="Dimensioni"
                                        class="mt-0 pt-1"
                                    ></v-text-field>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-content>
                                    Produttore
                                </v-list-item-content>
                                <v-list-item-content class="align-end">
                                    <v-text-field
                                        autocomplete="off"
                                        v-model="newScheda_produttore"
                                        hide-details="auto"
                                        :rules="requiredRules"
                                        label="Produttore"
                                        class="mt-0 pt-1"
                                    ></v-text-field>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-content>
                                    Bombola
                                </v-list-item-content>
                                <v-list-item-content class="align-end">
                                    <v-radio-group
                                        v-model="newScheda_marca_bombola"
                                        :rules="requiredRules"
                                        hide-details="auto"
                                        class="mr-3"
                                        row
                                    >
                                        <v-radio
                                            label="J"
                                            value="J"
                                        ></v-radio>
                                        <v-radio
                                            label="M"
                                            value="M"
                                        ></v-radio>
                                        <v-radio
                                            label="Ottone"
                                            value="Ottone"
                                        ></v-radio>
                                    </v-radio-group>
                                    <v-text-field
                                        autocomplete="off"
                                        v-model="newScheda_bombola"
                                        hide-details="auto"
                                        :disabled="!newScheda_marca_bombola"
                                        :rules="requiredRules"
                                        label="Grammi Bombola"
                                        class="mt-0 pt-1"
                                    ></v-text-field>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-content>
                                    Ampolla
                                </v-list-item-content>
                                <v-list-item-content class="align-end">
                                    <v-radio-group
                                        v-model="newScheda_marca_ampolla"
                                        :rules="requiredRules"
                                        hide-details="auto"
                                        class="mr-3"
                                        row
                                    >
                                        <v-radio
                                            label="J"
                                            value="J"
                                        ></v-radio>
                                        <v-radio
                                            label="M"
                                            value="M"
                                        ></v-radio>
                                    </v-radio-group>
                                    <v-text-field
                                        autocomplete="off"
                                        v-model="newScheda_ampolla"
                                        hide-details="auto"
                                        :disabled="!newScheda_marca_ampolla"
                                        :rules="requiredRules"
                                        label="Gradi Ampolla"
                                        class="mt-0 pt-1"
                                    ></v-text-field>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-content>
                                    Attuatori
                                </v-list-item-content>
                                <v-list-item-content class="align-end">
                                    <v-radio-group
                                        v-model="newScheda_attuatore"
                                        hide-details="auto"
                                        class="mr-3"
                                        row
                                    >
                                        <v-radio
                                            label="pirotecnico"
                                            value="pirotecnico"
                                        ></v-radio>
                                        <v-radio
                                            label="elettromagnete"
                                            value="elettromagnete"
                                        ></v-radio>
                                        <v-radio
                                            label="assente"
                                            value="assente"
                                        ></v-radio>
                                    </v-radio-group>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-content>
                                    Motore
                                </v-list-item-content>
                                <v-list-item-content class="align-end">
                                    <v-text-field
                                        autocomplete="off"
                                        v-model="newScheda_motore"
                                        hide-details="auto"
                                        label="Motore"
                                        class="mt-0 pt-1"
                                    ></v-text-field>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-content>
                                    Note
                                </v-list-item-content>
                                <v-list-item-content class="align-end">
                                    <v-text-field
                                        autocomplete="off"
                                        v-model="newScheda_note"
                                        hide-details="auto"
                                        label="Note"
                                        class="mt-0 pt-1"
                                    ></v-text-field>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                        <div class="text-center">
                            <v-btn
                                center
                                class="mt-3"
                                color="primary"
                                @click="submit"
                                :disabled="!formValid || loading"
                            >Salva</v-btn>
                        </div>
                    </v-form>
                </v-card>
            </v-tab-item>
        </v-tabs-items>
    </div>
</template>

<script>
import apiSchede from '@/js/pages/schede';
import { requiredRules } from '@/js/validationRules';

export default {
    name: 'schede',
    props: ['id_stabilimento', 'selected', 'disabled', 'noEmitTab', 'isMaster', 'actionScheda'],
    data() {
        return {
            token: this.$cookies.get('token'),
            newScheda_titolo: '',
            newScheda_colore: '',
            newScheda_dimensioni: '',
            newScheda_produttore: '',
            newScheda_marca_bombola: '',
            newScheda_bombola: '',
            newScheda_marca_ampolla: '',
            newScheda_ampolla: '',
            newScheda_marca_pirotecnico: '',
            newScheda_attuatore: '',
            newScheda_pirotecnico: '',
            newScheda_elettromagnete: '',
            newScheda_motore: '',
            newScheda_note: '',
            requiredRules: requiredRules,
            titoliSchede: [],
            titoloRules: [
                v => !!v || 'Campo Richiesto',
                v => v && v.length == 1 || 'Solo una lettera',
            ],
            loading: false,
            schede: [],
            tab: undefined,
            noDatas: false,
            formValid: false,
        }
    },
    computed: {
        hasPermission() {
            if(
                this.isMaster === undefined ||
                this.isMaster === null
            ) return true;
            else return this.isMaster;
        }
    },
    watch: {
        tab(){
            if(
                this.schede &&
                this.schede.length &&
                this.schede[this.tab] &&
                this.noEmitTab !== true
            ) this.$emit('setIdScheda',this.schede[this.tab]._id);

        },
        newScheda_colore(value) {
            if (
                value &&
                value.toString().match(/#[a-zA-Z0-9]{8}/)
            ) {
                this.newScheda_colore = value.substr(0, 7);
            } else if (
                value === undefined ||
                value === null ||
                value === ''
            ) this.newScheda_colore = '#FFFFFF';
        }
    },
    async created() {
        await this.initSchede(false);
    },
    methods: {
        async initSchede(getLast = false){
            this.titoliSchede = await apiSchede.getAllNames(this.token, this.id_stabilimento);
            if(
                this.titoliSchede &&
                this.titoliSchede.length
            ) this.titoloRules.push(v => (v && this.titoliSchede.indexOf(v) === -1 && this.titoliSchede.indexOf(v.toUpperCase()) === -1) || 'Nome già esistente!');
            this.schede = await apiSchede.getAll(this.token, this.id_stabilimento);

            if(
                this.schede &&
                this.schede[0]
            ) {
                if(getLast) this.tab = this.schede.length-1;
                else this.tab = 0;
            } else this.noDatas = true;
            if(
                this.selected &&
                this.schede &&
                this.schede.length
            ) {
                let tabFound = this.schede.findIndex(x => x._id == this.selected);
                if(tabFound !== -1) this.tab = tabFound;
            }
        },
        async submit(){
            this.loading = true;
            if(
                this.id_stabilimento &&
                this.$refs.form_data.validate() &&
                this.formValid
            ){
                let data = {
                    id_stabilimento: this.id_stabilimento,
                    lettera: this.newScheda_titolo,
                    colore: this.newScheda_colore,
                    dimensioni: this.newScheda_dimensioni,
                    produttore: this.newScheda_produttore,
                    marca_bombola: this.newScheda_marca_bombola,
                    bombola: this.newScheda_bombola,
                    marca_ampolla: this.newScheda_marca_ampolla,
                    ampolla: this.newScheda_ampolla,
                    marca_pirotecnico: this.newScheda_marca_pirotecnico,
                    attuatore: this.newScheda_attuatore,
                    pirotecnico: this.newScheda_pirotecnico,
                    elettromagnete: this.newScheda_elettromagnete,
                    motore: this.newScheda_motore,
                    note: this.newScheda_note,
                };
                this.schede = [];
                this.tab = undefined;
                await apiSchede.insertNew(this.token, data);
                await this.initSchede(true);

                this.newScheda_titolo = '';
                this.newScheda_colore = undefined;
                this.newScheda_dimensioni = '';
                this.newScheda_produttore = '';
                this.newScheda_marca_bombola = '';
                this.newScheda_bombola = '';
                this.newScheda_marca_ampolla = '';
                this.newScheda_ampolla = '';
                this.newScheda_marca_pirotecnico = '';
                this.newScheda_attuatore = '';
                this.newScheda_pirotecnico = '';
                this.newScheda_elettromagnete = '';
                this.newScheda_motore = '';
                this.newScheda_note = '';
                if(this.$refs.form_data) this.$refs.form_data.reset();
            }
            this.loading = false;
        },
        truncateText(text, lengthTruncation) {
            if(!lengthTruncation) lengthTruncation = 15;
            if (
                text &&
                text.length > lengthTruncation
            ) {
                return text.substring(0, lengthTruncation) + '...';
            }
            return text;
        },
        async deleteScheda(_id, Elength) {
            if(
                Elength == 0
            ){
                if(confirm('Eliminare la scheda?')) {
                    await apiSchede.deleteOne(this.token, { _id: _id });
                    await this.initSchede();
                }
            } else alert('Impossibile eliminare: ' + Elength + ' evacuatori collegati a questa scheda')
        }
    },
}
</script>

<style>
.border{
    background-color: LightGrey;
}
.no-alpha .v-color-picker__controls .v-color-picker__preview .v-color-picker__sliders .v-color-picker__alpha{
    display: none;
}
.no-alpha .v-color-picker__controls .v-color-picker__edit .v-color-picker__input:last-child{
    display: none;
}
.removeArrow > .v-icon .notranslate .fas .fa-chevron-right .theme--light{
    display: none;
}
.removeArrow .v-slide-group__next{
    display: none !important;
}
</style>